import React, {useEffect} from "react";

import {useAlert} from "react-alert";

import {Telephone, Printer} from "react-bootstrap-icons";

import Modal      from "components/shared/Modal";
import useForm    from "components/hooks/useForm";
import usePBStore from "modules/phonebook/hooks/usePBStore";
import {Save}     from "react-bootstrap-icons";

const InitialFormData = {
	phonenumber : "",
	faxnumber   : ""
};

const RoomEdit = ({room, show, setShow}) => {
	const alert                          = useAlert();
	const [formData, handleInput, clear] = useForm(InitialFormData);
	const save                           = usePBStore(store => store.roomSave);

	useEffect(() => {
		if (!room || !room.contact)
			return;
		clear({
			phonenumber : room.contact?.phonenumber || "",
			faxnumber   : room.contact?.faxnumber   || ""
		});
	}, [room, clear]);

	return (
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header>
				<Modal.Title>Rauminfos bearbeiten</Modal.Title>
			</Modal.Header>	
			<Modal.Body>
				<h5>{room?.name}</h5>
				<small><i>{room?.location}</i></small>
				<section className="grid form">
					<label>Telefon</label>	
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-addon1"><Telephone /></span>
						</div>
						<input className="form-control" type="tel"  name="phonenumber" onChange={handleInput} value={formData?.phonenumber} />
					</div>
					<label>Telefax</label>	
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text" id="basic-addon1"><Printer /></span>
						</div>
						<input type="tel" className="form-control" name="faxnumber" onChange={handleInput} value={formData.faxnumber} />
					</div>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => setShow(false)}>Abbrechen</button>
				<button className="btn btn-success" onClick={() => save(formData, alert)}><Save />Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default RoomEdit;