import React, {useEffect, useCallback} from "react";

import {useAlert} from "react-alert";

import {Envelope, Telephone, Printer} from "react-bootstrap-icons";

import useRMStore from "../../hooks/useRMStore";
import Modal      from "components/shared/Modal"
import useForm    from "components/hooks/useForm";
import Toggle     from "components/shared/Toggle";

const DefaultFormData = {
	name     : "",
	slug     : "",
	capacity : "",
	active   : true,
	location : "",
	extras   : "",
	notify   : "",
	catering : "",
	contact  : {
		phonenumber : ""
	}
};

const RoomsEdit = ({show, setShow, onHide, currentRoom}) => {
	const alert                          = useAlert();
	const save                           = useRMStore(store => store.roomSave);
	const [formData, handleInput, clear] = useForm(DefaultFormData);

	const handleContact = useCallback(event => {
		clear(oldValue => {
			const newValue = {...oldValue};

			if (!newValue.contact)
				newValue.contact = {};
			newValue.contact[event.target.name] = event.target.value;

			return newValue;
		});
	}, [clear]);

	useEffect(() => {
		if (!currentRoom)
			clear();
		else{
			clear(() => ({...currentRoom}));
		}
	}, [currentRoom, clear]);

	return (
		<>
			<Modal show={show} onHide={onHide} size="lg" scrollable={true}>
				<Modal.Header>
					<Modal.Title>{currentRoom?formData.name:'Neuer Raum'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid form">
						<label>Aktiv</label>
						<Toggle name="active" checked={formData.active} onChange={handleInput} label="" id="active" />

						<label>Name</label>
						<input className="form-control" name="name" onChange={handleInput} value={formData.name} required/>

						<label>Raumnummer</label>
						<input className="form-control" name="slug" onChange={handleInput} value={formData.slug} required/>
						
						<label>Personenkapazität</label>
						<input className="form-control" type="number" name="capacity" onChange={handleInput} value={formData.capacity} />

						<label>Raumverwaltung</label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1"><Envelope /></span>
							</div>
							<input className="form-control" name="notify" type="email" onChange={handleInput} value={formData?.notify} placeholder="Kontakt E-Mail Verwaltung" />
						</div>

						<label>Bewirtung</label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1"><Envelope /></span>
							</div>
							<input className="form-control" type="email"  name="catering" onChange={handleInput} value={formData?.catering} placeholder="Kontakt E-Mail Bewirtung" />
						</div>

						<label>Ort</label>
						<input className="form-control" name="location" onChange={handleInput} value={formData.location}/>

						<label>Raum Informationen</label>
						<textarea className="form-control" name="extras" onChange={handleInput} value={formData.extras} rows="5"/>

						<h4 style={{gridColumn : "span 2"}} className="my-3">Kontaktinformationen</h4>
						<label>Telefon</label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1"><Telephone /></span>
							</div>
							<input className="form-control" type="tel"  name="phonenumber" onChange={handleContact} value={formData?.contact?.phonenumber} />
						</div>
						<label>Telefax</label>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text" id="basic-addon1"><Printer /></span>
							</div>
							<input className="form-control" type="tel"  name="faxnumber" onChange={handleContact} value={formData?.contact?.faxnumber} />
						</div>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {
						clear(); 
						setShow(false);
					}}>Abbrechen</button>
					<button className="btn btn-success" onClick={() => save(alert, formData, () => {setShow(false); clear();})}>Raum {currentRoom ? "speichern" : "anlegen"}</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default RoomsEdit;
