import React, {useState, useEffect, useMemo} from "react";

import {useAlert} from "react-alert";

import {Pencil, Trash, Calendar3, PlusCircle} from "react-bootstrap-icons";

import SortableTable        from "components/shared/SortableTable";
import Spinner              from "components/shared/Spinner";
import SearchField          from "components/shared/SearchField";
import YesNoModal           from "components/shared/YesNoModal";
import PhoneNumberFormatter from "formatters/PhoneNumberFormatter";

import RoomsEdit        from "./modals/RoomsEdit";
import RoomsBookingList from "./modals/RoomsBookingList";
import useRMStore       from "../hooks/useRMStore";
import PageHeader       from "components/shared/PageHeader";

const RoomsList = () => {
	const alert                             = useAlert();
	const ready                             = useRMStore(store => store.roomsReady);
	const rooms                             = useRMStore(store => store.rooms);
	const [room, setRoom]                   = useRMStore(store => [store.room, store.roomSet]);
	const remove                            = useRMStore(store => store.roomDelete);
	const [filteredRooms, setFilteredRooms] = useState([]);
	const [search, setSearch]               = useState("");
	const [showDelete, setShowDelete]       = useState(false);
	const [showModal, setShowModal]         = useState(false);
	const [showBooked, setShowBooked]       = useState(false);

	const handleModalClose                  = (setter) => setter(false);

	const Header = useMemo(() => [
		{
			label  : "Bezeichnung",
			member : "name"
		},
		{
			label     : "Kapazität",
			member    : "capacity",
			formatter : value => `${value} Personen`
		},
		{
			label     : "Raumnummer",
			member    : "slug",
			formatter : value => <code>{value?.toUpperCase()}</code>
		},
		{
			label     : "Telefonnummer",
			member    : "contact.phonenumber",
			formatter : PhoneNumberFormatter
		},
		{
			label  : "Ort",
			member : "location"
		},
		{
			label      : "",
			member     : null,
			width      : "100px",
			sortable   : false,
			functional : ({member}) => {
				return (
					<>
						<button className="btn"><Pencil onClick={() => {setRoom(member); setShowModal(true);}}/></button>
						<button className="btn" onClick={() => {setRoom(member); setShowDelete(true);}}><Trash /></button>
						<button className="btn"><Calendar3 onClick={() => {setRoom(member); setShowBooked(true);}}/></button>
					</>
				);
			}
		}
	], [setRoom]);

	useEffect(() => {
		if (!search || search === "") {
			setFilteredRooms(rooms);

			return;
		}
		const regex = new RegExp(`${search}`, "gi");
		setFilteredRooms(rooms.filter(room => room.name.match(regex)))
	}, [search, rooms]);

	return (
		<>
			<PageHeader title="Raumliste" textNew="Raum anlegen" Icon={PlusCircle} actionNew={() => {setRoom(null); setShowModal(true);}}>
				<section className="grid three-thirds">
					<SearchField search={search} setSearch={setSearch} />	
				</section>
			</PageHeader>
			{ ready ?
				 	<>
						<SortableTable headers={Header} data={filteredRooms} sorting="name" />
						<YesNoModal title="Raum löschen" text={`Den Raum '${room?.name}' wirklich löschen?`} show={showDelete} setShow={setShowDelete} callback={() => remove(alert)} />
					</>
				: <Spinner /> }
			<RoomsEdit show={showModal} setShow={setShowModal} onHide={() => handleModalClose(setShowModal)} currentRoom={room}/>
			<RoomsBookingList show={showBooked} onHide={() => handleModalClose(setShowBooked)} currentRoom={room}/>
		</>
	);
};

export default RoomsList;